import React, { useState } from "react";
import styled from "styled-components";

import styles from "./HoverFx.module.scss";
import { useInView } from "react-intersection-observer";

import BGImg from "../images/debut_light.png";
import TrainingBotPic from "../images/trainingbot8.jpeg";
import BlitzBuild from "../images/blitzbuild.jpeg";
import Doorman from "../images/doorman.jpeg";

function Projects(props) {
  const [trainingBot2, setTrainingBot2] = useState(false);
  const [doorman, setDoorman] = useState(false);
  const [blitzBuild, setblitzBuild] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0
  });

  return (
    <PortfolioWrapper>
      {/* Project Development Section-------------------------------------------------------------- */}
      <h2>Developer Projects</h2>
      <hr style={{ marginBottom: "60px" }} />
      <Project style={{ backgroundImage: `url(${TrainingBotPic})` }}>
        <HoverProject
          onMouseEnter={e => setTrainingBot2(true)}
          onMouseLeave={e => setTrainingBot2(false)}
          className={
            trainingBot2 ? styles.popoverHover : styles.popoverHoverOut
          }
        >
          <p>Check it out!</p>
          <div>
            <button
              onClick={e =>
                window.open(
                  "https://github.com/labs12-training-bot-2",
                  "_blank"
                )
              }
            >
              Github
            </button>
            <button
              onClick={e =>
                window.open("https://www.trainingbot.app/", "_blank")
              }
            >
              Live Site
            </button>
          </div>
        </HoverProject>
        <ProjectCover>
          <div>
            <h3>Training Bot 2.0</h3>
            <p>
              A SaaS application my team inherited to add new features such as
              Slack integration and a responses dashboard that works with Slack,
              SendGrid, and Twilio.
            </p>
          </div>
        </ProjectCover>
      </Project>
      <ProjectDiscription>
        <ul>
          <li>Training Bot 2.0 is an application that allows companies and managers
          to handle their employee continuing education & training resources and
          manage the communications around those resources.
          </li>
          <br />
          <br />
          <li>The team consisted of myself and four other full stack engineers that
          worked for 5 weeks to refactor an existing code base to be more
          scalable, handle responses from multiple API's such as Slack,
          SendGrid, and Twilio, handle payments with Stripe API, authentication
          from Auth0.
          </li>
          <br />
          <br />
          <li>I focused on Strip API integration, refactoring the inherited backend code, 
          writing clear and professional documentation, and helping redesign database schema from the group up
          </li>
          <br />
          <br />
          <li>Tech Stack: The frontend was written in React and Redux, the backend
          in Node and Express, and Material UI for design.
          </li>
         </ul> 
       
      </ProjectDiscription>

      {/* Doorman */}

      <Project style={{ backgroundImage: `url(${Doorman})` }}>
        <HoverProject
          onMouseEnter={e => setDoorman(true)}
          onMouseLeave={e => setDoorman(false)}
          className={doorman ? styles.popoverHover : styles.popoverHoverOut}
        >
          <p>Check it out!</p>
          <div>
            <button
              onClick={e =>
                window.open("https://github.com/notwrong/doorman", "_blank")
              }
            >
              Github
            </button>
            <button
              onClick={e =>
                window.open("https://doorman.notwrong.dev/", "_blank")
              }
            >
              Live Site
            </button>
          </div>
        </HoverProject>
        <ProjectCover>
          <div>
            <h3>Doorman</h3>
            <p>
              A Hackathon project that allows you to white list or black list
              Github users to auto accept or deny repo invites.
            </p>
          </div>
        </ProjectCover>
      </Project>
      <ProjectDiscription>
        <ul>
        <li>
        This was the work product of the Lambda School Summer Hackathon. 
        My team and I all had experience as Team Leads or Section Leads and wanted to find a way to automate 
        acceping github repo requests from the same people every day 
        for different projects.
        </li>
          <br />
          <br />
        <li>
        Since this was a real pain point for us we decided to use the Hackathon to build a tool that would allow us to whitelist 
        (or blacklist) repo requests from people. So if we got 8 requests to collaborate on a repo and those individuals were on 
        the white list our app would auto accept.
        </li>
          <br />
          <br />
        <li>
        Tech Stack: Vue for the frontend, Vuetify for UI, and Firebase as our backend. 
        We only gave ourselves two days to learn how to use Vue and Vuetify as an extra challenge. 
        We also integrated with Github's API for smart search feature too.
        </li>
        <br />
        <br />
        <li>
        I focused on UX/UI design.
        </li>
        </ul>
      </ProjectDiscription>

      {/* Meilleur Amour */}

      {/* <Project style={{ backgroundImage: `url(${Meilleur})` }}>
        <HoverProject
          onMouseEnter={e => setMeilleuramour(true)}
          onMouseLeave={e => setMeilleuramour(false)}
          className={
            meilleuramour ? styles.popoverHover : styles.popoverHoverOut
          }
        >
          <p>Check it out!</p>
          <div>
            <button
              onClick={e =>
                window.open(
                  "https://github.com/GannonDetroit/meilleur-amour",
                  "_blank"
                )
              }
            >
              Github
            </button>
            {/* <button
              onClick={e => window.open("www.meilleuramour.com", "_blank")}
            >
              Live Site
            </button> */}
          {/* </div>
        </HoverProject>
        <ProjectCover>
          <div>
            <h3>Meilleur Amour</h3>
            <p>
              A landing page for company that sells a product for at home
              massage therapy.
            </p>
          </div>
        </ProjectCover>
      </Project>
      <ProjectDiscription>
        <ul>
          <li>
          SITE UNDER CONSTRUCTION: A landing page for an online based company.
          </li>
        </ul>
        
      </ProjectDiscription>  */}

      {/* PROJECT MANAGEMENT SECTION--------------------------------------------------------------------------------------------------- */}

      <h2 ref={ref} className={inView ? styles.fadeInText : styles.fadeOutText}>
        Team Lead Projects
      </h2>
      <hr style={{ marginBottom: "60px" }} />

      

      {/* Blitz Build */}

      <Project style={{ backgroundImage: `url(${BlitzBuild})` }}>
        <HoverProject
          onMouseEnter={e => setblitzBuild(true)}
          onMouseLeave={e => setblitzBuild(false)}
          className={blitzBuild ? styles.popoverHover : styles.popoverHoverOut}
        >
          <p>Check it out!</p>
          <div>
            <button
              onClick={e =>
                window.open(
                  "https://github.com/GannonDetroit/blitz-build-fe?organization=GannonDetroit&organization=GannonDetroit",
                  "_blank"
                )
              }
            >
              Frontend
            </button>
            <button
              onClick={e =>
                window.open(
                  "https://github.com/GannonDetroit/blitz-build-be",
                  "_blank"
                )
              }
            >
              Backend
            </button>
            <button
              onClick={e =>
                window.open("https://blitz-build.netlify.com/", "_blank")
              }
            >
              Live Site
            </button>
          </div>
        </HoverProject>
        <ProjectCover>
          <div>
            <h3>Blitz Build</h3>
            <p>
            A SaaS program for the construction management industry.
            </p>
          </div>
        </ProjectCover>
      </Project>
      <ProjectDiscription>
        <ul>
          <li>A program that can help Construction Management superintendents and their managers to keep track of their builds.</li> 
          <br />
          <br />
          <li>Auto generate reports, log delays, track tasks, remain on budget, and create templates of common build patterns. </li>
          <br />
          <br />
          <li>Tech Stack: Frontend with React and Redux, Backend is Node and Express, Database with Postgres. </li>
          <br />
          <br />
          <li>I am in charge of overall project management and DevOps (project still on going).</li>
        </ul>
          <br />
          <br />
        
        {/* <MobileLinks>
          <p>
            github:{" "}
            <a
              href="https://www.trainingbot3.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.trainingbot3.com/
            </a>
          </p>
          <p>
            live site:{" "}
            <a
              href="https://www.trainingbot.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.trainingbot.app/
            </a>
          </p>
        </MobileLinks> */}
      </ProjectDiscription>

    </PortfolioWrapper>
  );
}

export default Projects;

const PortfolioWrapper = styled.div`
  width: 100vw;
  padding: 0 55px 50px 55px;
  @media (max-width: 850px) {
    padding: 0 0 50px 0;
  }
  background-image: url(${BGImg});
  font-family: "Montserrat", sans-serif;
  h2 {
    padding-top: 80px;
    font-size: 45px;
    color: black;
    margin: 0;
    font-weight: 200;
  }
`;

const Project = styled.div`
  margin: 30px auto 0 auto;
  box-sizing: border-box;
  width: 90vw;
  max-width: 1000px;
  min-height: 275px;
  background: #28af3e;
  background-size: cover;
  background-position: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 650px) {
    min-height: 225px;
  }
`;

const HoverProject = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #333333;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  p {
    font-size: 34px;
    font-weight: 800px;
    color: white;
    margin: 0;
    @media (max-width: 600px) {
      font-size: 28px;
    }
  }
  button {
    cursor: pointer;
    width: 155px;
    height: 40px;
    margin: 10px;
    color: white;
    font-size: 20px;
    opacity: 1;
    @media (max-width: 600px) {
      width: 105px;
      height: 30px;
    }
    &:nth-child(1) {
      background: #19ab33;
      border: 2px solid #19ab33;
    }
    &:nth-child(2) {
      background: white;
      border: 2px solid white;
      color: #19ab33;
    }
    &:nth-child(3) {
      background: #19ab33;
      border: 2px solid #19ab33;
    }
  }
`;
const ProjectCover = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 650px) {
    min-height: 225px;
    height: 225px;

    p {
      display: none;
    }
  }
  div {
    width: 80%;
    padding: 60px 0;
    background: rgba(255, 255, 255, 0.99);
    border: 1px solid black;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 650px) {
      height: 155px;
    }
    h3 {
      color: #f53b54;
      font-size: 35px;
      font-weight: 400;
      margin: 0;
      @media (max-width: 650px) {
        font-size: 28px;
      }
    }
    p {
      color: black;
      padding: 0 20px;
      font-weight: 400;
      @media (max-width: 650px) {
        font-size: 14px;
      }
    }
  }
`;

const ProjectDiscription = styled.div`
  margin: 0 auto 30px auto;
  box-sizing: border-box;
  width: 80vw;
  max-width: 900px;
  min-height: 100px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.05);
  position: relative;
  font-family: "Montserrat", sans-serif;
  p {
    font-size: 18px;
    color: black;
    font-weight: bolder;
  }
`;

const MobileLinks = styled.div`
  display: none;
  @media (max-width: 650px) {
    display: block;
  }
  a {
    color: blue;
  }
`;


//consider making an ethical hacking section and adding parts of your tool kit there