import React, { useState } from "react";
import styled from "styled-components";
import BGTexture from "../images/ep_naturalblack.png";
import styles from "./HoverFx.module.scss";
import django2 from '../images/django3.png'
import { useInView } from "react-intersection-observer";

export default function AboutMe() {
  const [css, setCss] = useState(false);
  const [html, setHtml] = useState(false);
  const [js, setJs] = useState(false);
  const [react, setReact] = useState(false);
  const [node, setNode] = useState(false);
  const [python, setPython] = useState(false);
  const [django, setDjango] = useState(false)
  const [git, setGit] = useState(false);
  const [vue, setVue] = useState(false);

  //ref for page position
  const [ref, inView] = useInView({
    threshold: 0
  });
  const [textRef, texInView] = useInView({
    threshold: 0
  });

  return (
    <AboutMeStripe style={{ backgroundImage: `url(${BGTexture})` }}>
      <AboutMeInfo>
        <h2
          className={inView ? styles.fadeInText : styles.fadeOutText}
          ref={ref}
        >
          About Me
        </h2>
        <hr />
        <Slogan className={inView ? styles.fadeInText : styles.fadeOutText}>
          “No man is more unhappy than he who never faces adversity. For he is
          not permitted to prove himself” <br />- Seneca
        </Slogan>
        <AboutMeWrapper>
          <AboutMeText>
            <p
              ref={textRef}
              className={texInView ? styles.fadeInText : styles.fadeOutText}
            >
              <span>Greetings,</span> <br /> <br /> My name is Gannon Darcy.{" "}
              <br />
              <br />
              Full Stack Engineer with experience bridging the gap to help technical and non-technical teams communicate, plan, 
              and organize in order to work exceptionally well together. Passionate about Cyber Security.
              <br />
              <br />
              Proven leadership in training others how to program through work
              as a Teaching Assistant and Team Lead. <br />
              <br />
              Main languages are JavaScript and Python and there frameworks in React and Django.
              <br />
              <br />
              Comfortable working on diverse teams in fast-paced and ambiguous
              environments, while being able to function autonomously.
              <br />
              <br />A proactive self-starter with a passion for learning and
              growth. <br />
            </p>
          </AboutMeText>

          <AboutMeDecals>
            <h3>Technologies</h3>
            <TechnologyEven style={{ position: "relative" }}>
              <i className="fab fa-js" />
              <h4>JavaScript</h4>
              <PopOver
                className={js ? styles.popoverHover : styles.popoverHoverOut}
                onMouseEnter={e => {
                  setJs(true);
                }}
                onMouseLeave={e => {
                  setJs(false);
                }}
              >
                <p>Writing DRY: ES6 syntax JavaScript.</p>
              </PopOver>
            </TechnologyEven>
            <TechnologyOdd style={{ position: "relative" }}>
              <i className="fab fa-react" />
              <h4>React JS</h4>
              <PopOver
                className={react ? styles.popoverHover : styles.popoverHoverOut}
                onMouseEnter={e => {
                  setReact(true);
                }}
                onMouseLeave={e => {
                  setReact(false);
                }}
              >
                <p>Primary Framework is React/Redux including Hooks</p>
              </PopOver>
            </TechnologyOdd>
            <TechnologyEven style={{ position: "relative" }}>
              <i className="fab fa-node-js" />
              <h4>Node JS </h4>
              <PopOver
                className={node ? styles.popoverHover : styles.popoverHoverOut}
                onMouseEnter={e => {
                  setNode(true);
                }}
                onMouseLeave={e => {
                  setNode(false);
                }}
              >
                <p> Proficient in Node and with using the Express Framework</p>
              </PopOver>
            </TechnologyEven>

            <TechnologyOdd style={{ position: "relative" }}>
              <i className="fab fa-python" />
              <h4>Python</h4>
              <PopOver
                className={
                  python ? styles.popoverHover : styles.popoverHoverOut
                }
                onMouseEnter={e => {
                  setPython(true);
                }}
                onMouseLeave={e => {
                  setPython(false);
                }}
              >
                <p>Proficient with Python</p>
              </PopOver>
            </TechnologyOdd>
            <TechnologyEven style={{ position: "relative" }}>
              <img src={django2} alt="Django" style={{ height: '70px', width: '70px' }} />
              <h4>Django</h4>
              <PopOver
                className={
                  django ? styles.popoverHover : styles.popoverHoverOut
                }
                onMouseEnter={e => {
                  setDjango(true);
                }}
                onMouseLeave={e => {
                  setDjango(false);
                }}
              >
                <p>Proficient with Django</p>
              </PopOver>
            </TechnologyEven>
            <TechnologyOdd style={{ position: "relative" }}>
              <i className="fab fa-github" />
              <h4>Git</h4>
              <PopOver
                className={git ? styles.popoverHover : styles.popoverHoverOut}
                onMouseEnter={e => {
                  setGit(true);
                }}
                onMouseLeave={e => {
                  setGit(false);
                }}
              >
                <p>Comfortable using Git and Github on production ready projects.</p>
              </PopOver>
            </TechnologyOdd>
            <TechnologyEven style={{ position: "relative" }}>
              <i className="fab fa-vuejs" />
              <h4>Vue</h4>
              <PopOver
                className={vue ? styles.popoverHover : styles.popoverHoverOut}
                onMouseEnter={e => {
                  setVue(true);
                }}
                onMouseLeave={e => {
                  setVue(false);
                }}
              >
                <p>Proficient with Vue and Vuetify</p>
              </PopOver>
            </TechnologyEven>
            <TechnologyOdd style={{ position: "relative" }}>
              <i className="fab fa-css3-alt" />
              <h4>CSS</h4>
              <PopOver
                className={css ? styles.popoverHover : styles.popoverHoverOut}
                onMouseEnter={e => {
                  setCss(true);
                }}
                onMouseLeave={e => {
                  setCss(false);
                }}
              >
                <p>Experienced in CSS, SCSS, LESS</p>
              </PopOver>
            </TechnologyOdd>
            <TechnologyEven style={{ position: "relative" }}>
              <i className="fab fa-html5" />
              <h4>HTML</h4>
              <PopOver
                className={html ? styles.popoverHover : styles.popoverHoverOut}
                onMouseEnter={e => {
                  setHtml(true);
                }}
                onMouseLeave={e => {
                  setHtml(false);
                }}
              >
                <p>Semantic HTML and JSX</p>
              </PopOver>
            </TechnologyEven>
          </AboutMeDecals>
        </AboutMeWrapper>
      </AboutMeInfo>
    </AboutMeStripe>
  );
}

const AboutMeStripe = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 100px 40px;
  padding-bottom: 100px;
  width: 100vw;
  background: #343434;
  clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 100%);
  color: white;
  @media (max-width: 900px) {
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 100%);
    padding: 100px 10px;
  }
`;

const AboutMeInfo = styled.div`
  margin: auto;
  width: 100%;
  min-height: 300px;
  max-width: 1800px;
  padding: 15px;
  font-family: "Montserrat", sans-serif;
  h2 {
    font-size: 60px;
    margin: 10px;
    font-weight: 100;

    @media (max-width: 900px) {
      font-size: 48px;
    }
  }
`;

const AboutMeText = styled.div`
  text-align: start;
  padding: 10px;
  width: 45%;
  font-size: 20px;
  span {
    font-weight: 800;
    font-size: 24px;
    @media (max-width: 900px) {
      font-size: 20px;
    }
  }
  @media (max-width: 900px) {
    width: 100%;
    border-right: 0px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
    font-size: 16px;
  }
`;

const AboutMeDecals = styled.div`
  padding: 10px;
  width: 55%;
  color: black;
  background: white;
  @media (max-width: 900px) {
    width: 100%;
    margin-top: 25px;
  }
  i {
    font-size: 75px;
    @media (max-width: 650px) {
      font-size: 50px;
    }
  }
  h3 {
    font-size: 25px;
    font-weight: 400;
    @media (max-width: 650px) {
      font-size: 20px;
    }
  }
`;

const AboutMeWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const TechnologyEven = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px auto;
  background: rgba(0, 0, 0, 0.03);
  padding: 5px 80px;
  font-weight: 400;
  @media (max-width: 650px) {
    padding: 5px 40px;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  h4 {
    font-size: 20px;
    margin: 0;
    color: #e8374f;
  }
`;
const TechnologyOdd = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px auto;
  background: rgba(0, 0, 0, 0.09);
  padding: 5px 80px;
  font-weight: 400;
  @media (max-width: 650px) {
    padding: 5px 40px;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  h4 {
    font-size: 20px;
    margin: 0;
    color: #e8374f;
  }
`;

const PopOver = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #07a828;
  color: white;
  opacity: 0;
  cursor: default;
  p {
    cursor: default;
  }
`;

const Slogan = styled.p`
  font-size: 17px;
  font-weight: 400;
  font-style: italic;
  margin: 10px auto 20px;
  width: 90%;
  max-width: 700px;
  font-family: "Montserrat", sans-serif;
  @media (max-width: 900px) {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

const djangoImg = styled.img`
  width: 18%;
`;
